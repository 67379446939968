@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'AladinRegular';
  src: local('Aladin-Regular'), url(./fonts/Aladin-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}