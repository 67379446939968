.print-only {
  display: none;
}

@media print {
  .no-print {
    display: none !important;
  }

  .print-only {
    display: block;
  }
  .mantine-b146xb tbody tr td {
    font-size: 11px;
  }
  .print-container {
    width: 90% !important;
    margin: auto;
    /* padding-top:100px; */
  }
}
 


@media print {
    .pagebreak {
        clear: both;
        page-break-after: always;
    }
}

@page:first {
  margin-top: 0cm;
}

@page {
  size: auto;
    margin: 2cm 0cm 2cm 0cm;

    @top-center {
        content: element(pageHeader);
    }

    @bottom-center {
        content: element(pageFooter);
    }
}


#pageHeader{
    position: running(pageHeader);
}

#pageFooter{
    position: running(pageFooter);
}
